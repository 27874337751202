/* eslint-disable import/no-extraneous-dependencies */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { graphql } from 'gatsby';

import { pushFacebookCategory } from '../../../../utils/analyticUtils';

function AdditionalFacebookPixel({ pageData }) {
  const pixels = get(pageData, 'additionalFacebookPixel');

  useEffect(() => {
    if (pixels) {
      pixels.forEach(pixel => {
        pushFacebookCategory(get(pixel, 'pixel', ''));
      });
    }
  }, [pixels]);

  return null;
}

export const query = graphql`
  fragment FacebookPixelFragment on ContentfulFacebookPixel {
    pixel
  }
`;

AdditionalFacebookPixel.propTypes = {
  pageData: PropTypes.object,
};

AdditionalFacebookPixel.defaultProps = {
  pageData: null,
};

export default AdditionalFacebookPixel;
