import React from 'react';
import PropTypes from 'prop-types';

import { map, get } from 'lodash';
import { Helmet } from 'react-helmet';

function CustomStyleFontFamilyLinks({ pageData }) {
  const fontFamilyLinks = get(pageData, 'customStyleFontFamilyLinks');
  return (
    <Helmet>
      {map(fontFamilyLinks, (fontFamilyLink, index) => (
        <link key={index} href={fontFamilyLink} rel="stylesheet" />
      ))}
    </Helmet>
  );
}

CustomStyleFontFamilyLinks.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

CustomStyleFontFamilyLinks.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default CustomStyleFontFamilyLinks;
