import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { map, get, includes, concat, compact } from 'lodash';
import { Helmet } from 'react-helmet';
import { contentfulContentTypes } from '../../../../constants';

function PlayableIframeScript({ pageData }) {
  const pageType = get(pageData, 'internal.type');

  const referenses = useMemo(() => {
    if (pageType === contentfulContentTypes.LandingPage) {
      let columnsContent = [];
      const rows = get(pageData, 'contentRows');
      rows.forEach(row => {
        const columns = get(row, 'columns');
        columns.forEach(column => {
          columnsContent = concat(columnsContent, get(column, 'content.references'));
        });
      });
      return compact(columnsContent);
    }

    const content = get(pageData, 'content');
    return compact(get(content, 'references'));
  }, [pageData, pageType]);

  const hasIframe = useMemo(() => {
    return includes(map(referenses, '__typename'), contentfulContentTypes.IFrameModule);
  }, [referenses]);

  if (!hasIframe) {
    return null;
  }

  return (
    <Helmet>
      <script src="https://files.cdn.leadfamly.com/campaign/sdk/utilities.responsive_iframe.min.js" defer />
    </Helmet>
  );
}

PlayableIframeScript.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PlayableIframeScript.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default PlayableIframeScript;
