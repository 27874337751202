/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { withAuthenticationRequired } from '@auth0/auth0-react';

function ProtectedRoute({ children }) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

ProtectedRoute.defaultProps = {
  children: null,
};

export default withAuthenticationRequired(ProtectedRoute);
