/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */

import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { useAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core';

import ProtectedRoute from '../../../components/authentication/ProtectedRoute/ProtectedRoute';
import { anoraProUnprotectedPages } from '../../../constants';

import LandingPageTemplate from '../LandingPageTemplate';
import LoadingIndicator from '../../../components/shared/LoadingIndicator';

const styles = () => ({
  loading: {
    margin: '40px',
  },
});

function AnoraProLandingPageTemplate({ classes, ...props }) {
  const fullPath = get(props, 'data.contentfulLandingPage.fullPath');
  const isUnprotectedPage = includes(Object.values(anoraProUnprotectedPages), fullPath);
  const { isAuthenticated, isLoading } = useAuth0();

  const isLoginPage = isEqual(fullPath, anoraProUnprotectedPages.login);
  const isHomePage = isEqual(fullPath, '/');

  useEffect(() => {
    if (isLoginPage && isAuthenticated && !isLoading) {
      navigate('/');
    }
    if (isHomePage && !isAuthenticated && !isLoading) {
      navigate(`${window.location.origin}${anoraProUnprotectedPages.login}`);
    }
  }, [isAuthenticated, isLoginPage, isHomePage, isLoading]);

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <LoadingIndicator />
      </div>
    );
  }
  if (isUnprotectedPage && !isAuthenticated && !isLoading) {
    return <LandingPageTemplate {...props} />;
  }
  return (
    <ProtectedRoute>
      <LandingPageTemplate {...props} />
    </ProtectedRoute>
  );
}

AnoraProLandingPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AnoraProLandingPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withStyles(styles)(AnoraProLandingPageTemplate);
