import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaPlayableIframeScript from './viinimaa';

function PlayableIframeScript(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaPlayableIframeScript {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaPlayableIframeScript {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaPlayableIframeScript {...props} />;
  }

  return null;
}

export default PlayableIframeScript;
